.on-hover-child {
    opacity: 0 !important;
    /*display: none !important;*/
}

.on-hover-parent:hover .on-hover-child {
    transition: opacity 200ms ease-in-out;
    opacity: 1 !important;
    /*display: block !important;*/
}
