*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10.5rem; /* or desired width */
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: auto;
}



.clout-logo{
  max-width: 100px;
  margin: 48px auto 0 auto;
}

.sidebar-buttons{
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.white-text {
  color: white!important;
}

.login-container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}


.login-image-container{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-container{
  background-color: #13426B;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container{
  background-color: #fff;
  height: 40vh;
  min-width: 40%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5%;
}

.text-logo{
  max-width: 480px;
}

.header{
  height: 10vh;
  display: flex;
  align-items: center;
  /*padding: 20px;*/
}

.main-content{
  margin-left: 10.5rem;
  padding: 20px;
  margin-bottom: 16rem;
  width: 100%;
}

.pecssrr-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pecssrr-form{
  border: 1px solid #212C44;
  padding: 20px 50px;
  border-radius: 4px;
  max-width: 80%;
  min-width: 45vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  overflow-y: auto;
  
}

.select-client-name{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select-client-company{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pecsrr-input{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.back-button{
  margin-top: 15px!important;
  text-decoration: underline;
}

.back-button:hover{
  cursor: pointer;
}

.response {
  white-space: pre-wrap;
}

.pecsrr-output{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
}

.pecsrr-result-with-title{
  display: flex;
  flex-direction: column;
}

.item-container{
  padding: 20px;
}

.postbody-container{
  flex: 2; 
  padding: 20px;
  overflow-x: hidden;
}

.space-between{
  display: flex;
  justify-content: space-between;
}

.all-pecsrr-cotainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

.pecsr-list{
  width: 100%;
}

.central-post {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pecsrr-details {
  padding: 20px;
  min-height: 80vh;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Add this line */
  border-radius: 4px;
  border: 1px solid #212c44;

}

.pecsrr-detail-titles {
  padding: 20px;
  flex: 1;
}

.pecsrr-detail-contents {
  padding: 20px;
  flex: 2;
  margin-top: 20px; /* Add this line */
}

.post-details-list{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}


.new-client-form-container{
  height: 80vh;
  min-width: 40%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5%;
}

.new-client-name{
  border: 1px solid #212c44;
  padding: 50px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  gap: 20px;
  width: 40%;
}

.new-company-form-container{
  height: 80vh;
  min-width: 40%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5%;
}

.new-company{
  border: 1px solid #212c44;
  padding: 50px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 40%;
}

.create-new-user{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.new-user-form{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #212C44;
  border-radius: 4px;
  padding: 50px;
}

.linkedin-link-container{
  width: 100%;
}

.linkedin-link{
  text-decoration: underline;
}

.linkedin-link:hover{
  cursor: pointer;
}

.problem-container{
  margin: 20px 0;
  max-width: 100%;
}

.problem-text{
  max-width: 40%;
}

.create-post-prompt-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-post-prompt{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  border: 1px solid black;
  padding: 50px;
  border-radius: 4px;
}

.update-prompt-field{
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  border-radius: 4px;
}

.active-key-container{
  padding: 10px;
  margin: 10px;
}

.list-of-keys{
  padding: 10px;
  margin: 10px;
}

.create-open-ai-key-container{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  border: 1px solid black;
  margin-top: 20px;
}

.saved-button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}